
	frappe.templates['custom_shipment_service_selector'] = `
<div style="overflow-x:scroll;">
	{% if (data.length) { %}
		<table class="table table-bordered table-hover">
			<thead class="grid-heading-row">
				<tr>
					{% for (var i = 0; i < header_columns.length; i++) { %}
						<th style="padding-left: 12px;" >{{ header_columns[i] }}</th>
					{% } %}
				</tr>
			</thead>
			<tbody>
				{% for (var i = 0; i < data.length; i++) { %}
					<tr>
						<td class="service-info" style="width:20%;">{{ data[i].service_provider }}</td>
						<td class="service-info" style="width:20%;">{{ format_currency(data[i].price, "CLP", 0) }}</td>
						<td style="width:10%;vertical-align: middle;">
							<button
								data-type={{data[i].price}}
								id={{data[i].service_provider}} type="button" class="btn">
								Select
							</button>
						</td>
					</tr>
				{% } %}
			</tbody>
		</table>
	{% } else { %}
	<div style="text-align: center; padding: 10px;">
		<span class="text-muted">
			{{ __("No Services Available") }}
		</span>
	</div>
	{% } %}
</div>

<style type="text/css" media="screen">
.modal-dialog {
	width: 750px;
}
.service-info {
	vertical-align: middle !important;
	padding-left: 12px !important;
}
.btn {
	border-color: black;
}
.btn:hover {
	background-color: #dedede;
}
.ship {
	font-size: 16px;
}
</style>`;
